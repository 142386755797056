<template>
  <div class="container-tabela">
    <b-row class="container-indicadores">
      <b-col cols="6">
        <box-indicadores
          :fechamento="fechamento"
          :descricao="descricao"
          :desenho="desenho"
          :indicador="indicador"
          v-if="descricao && fechamento"
        />
      </b-col>
      <b-col cols="2" class="p-0">
        <root-cause-bar v-if="analysis" :analysis="analysis" />
      </b-col>
      <b-col cols="4">
        <b-card no-body class="container-risk">
          <risk-bar
            v-if="tabela.risks.quantidade"
            :risks="tabela.risks.quantidade"
            :cores="tabela.risks.cor"
            :categorias="tabela.risks.label"
          />
          <risk-legenda />
        </b-card>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12" class="my-2">
        <b-row align-h="end">
          <b-col cols="auto">
            <b-form-group>
              <b-form-select
                id="per-page-select"
                v-model="itemsPerPage"
                :options="pageOptions"
                size="sm"
              >
              </b-form-select>
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-pagination
              v-model="currentPage"
              :total-rows="tabela.datas.length"
              :per-page="itemsPerPage"
              align="fill"
              size="sm"              
            ></b-pagination>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <table class="table table-bordered table-condensed">
      <thead>
        <tr>
          <th scope="col">Ponto</th>
          <th scope="col">AX</th>
          <th scope="col">CALC</th>
          <th scope="col">LIE</th>
          <th scope="col">LSE</th>
          <th scope="col">XM</th>
          <th scope="col">RG</th>
          <th scope="col">SIGMA</th>
          <th scope="col">{{ indicador == "cp" ? "CP" : "PP" }}</th>
          <th scope="col">{{ indicador == "cp" ? "CPK" : "PPK" }}</th>
          <th scope="col">RISK</th>
          <th scope="col">N</th>
          <th
            scope="col"
            v-for="(data, index) in currentPageItems(tabela.datas)"
            :key="data + index"
          >
            {{ data | data }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(item, index) in tabela.caracteristicas"
          :key="item.ponto + index"
        >
          <td>{{ item.ponto }}</td>
          <td>{{ item.ax.toUpperCase() }}</td>
          <td>{{ item.calc }}</td>
          <td>{{ item.lie | casas(3) }}</td>
          <td>{{ item.lse | casas(3) }}</td>
          <td
            :style="
              'background-color:' +
              item.medcor +
              (item.medcor == 'red' ? '; color: white' : '')
            "
          >
            {{ item.med | casas(3) }}
          </td>
          <td>{{ item.range | casas(3) }}</td>
          <td>{{ item.sigma | casas(3) }}</td>
          <td
            :style="
              'background-color:' +
              item.cpcor +
              (item.cpcor == 'red' ? '; color: white' : '')
            "
          >
            {{ item.cp | casas(3) }}
          </td>
          <td
            :style="
              'background-color:' +
              item.cpkcor +
              (item.cpkcor == 'red' ? '; color: white' : '')
            "
          >
            {{ item.cpk | casas(3) }}
          </td>
          <td :style="'background-color:' + item.riskcor">{{ item.risk }}</td>
          <td>{{ item.desvios.length }}</td>
          <td
            v-for="(desvios, index) in currentPageItems(item.desvios)"
            :key="desvios + index"
          >
            {{ desvios | casas(3) }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import RiskLegenda from "@/components/RiskLegenda.vue";
import RiskBar from "@/components/RiskBar.vue";
import BoxIndicadores from "@/components/BoxIndicadores.vue";
import RootCauseBar from "@/components/RootCauseBar.vue";

export default {
  name: "Tabela",
  components: {
    RiskLegenda,
    RiskBar,
    BoxIndicadores,
    RootCauseBar,
  },
  props: {
    analysis: null,
    tabela: {
      risks: {
        quantidade: null,
        cor: null,
      },
    },
    fechamento: null,
    descricao: String,
    desenho: String,
    indicador: null,
  },
  data() {
    return {
      itemsPerPage: 10,
      currentPage: 1,
      pageOptions: [
        { value: 5, text: this.$t("paginacao.5-itens-por-pagina") },
        { value: 10, text: this.$t("paginacao.10-itens-por-pagina") },
        { value: 15, text: this.$t("paginacao.15-itens-por-pagina") },
        { value: 30, text: this.$t("paginacao.30-itens-por-pagina") },
        { value: 60, text: this.$t("paginacao.60-itens-por-pagina") },
        { value: 100, text: this.$t("paginacao.100-itens-por-pagina") },
      ],
    };
  },

  methods: {
    totalPages(desvios) {
      return Math.ceil(desvios.length / this.itemsPerPage);
    },
    currentPageItems(desvios) {
      const start = (this.currentPage - 1) * this.itemsPerPage;
      const end = start + this.itemsPerPage;
      return desvios.slice(start, end);
    },    
  },

  computed: {},
};
</script>

<style scoped>
.container-tabela {
  overflow-y: auto;
}

.table-condensed {
  font-size: 12px;
  padding: 0;
  text-align: center;
}
tr td,
th {
  padding: 2px !important;
}
.container-indicadores {
  height: 140px;
}
.container-risk {
  height: 120px;
}
</style>
