<template>
  <div class="familia pt-3">
    <b-breadcrumb>
      <b-breadcrumb-item :to="'/familias'"
        ><b-icon
          icon="house-fill"
          scale="1.25"
          shift-v="1.25"
          aria-hidden="true"
        ></b-icon>
       {{ $t("breadcrumb.familias")}}
      </b-breadcrumb-item>
      <b-breadcrumb-item active>{{ $t("breadcrumb.familia")}}</b-breadcrumb-item>
    </b-breadcrumb>

    <SelecaoPeriodo :mostrarTurno="true" :mostrarVisualizacao="false" />
    
    <b-card no-body class="px-5 py-3 mt-3">
      <b-row align-v="center" align-h="between">
        <div class="card-imagem">
          <b-row align-v="center">
            <b-img center height="150" :src="familia.imagem | urlImagem" />
            <b-col>
              <h4>{{ familia.descricao }}</h4>
            </b-col>
          </b-row>
        </div>

        <b-row>
          <b-col cols="auto">
            <label>{{$t('label.fechamentos')}}</label>
            <b-form-select
              v-model="fechamento"
              :options="optionsFechamentos"
            ></b-form-select>
          </b-col>          
        </b-row>

        <div>
          <b-button-toolbar
            aria-label="Toolbar with button groups and dropdown menu"
          >
            <b-button-group class="mx-1" v-if="user.permissao =='acesso_total' || user.permissao =='relatorio' ">
              <b-button @click="showModalComponente()"
                >{{$t("botoes.novoComponente")}}</b-button
              >
            </b-button-group>

            <b-dropdown class="mx-1" right :text="$t('botoes.fechamento')">
              <b-dropdown-item 
              v-if="user.permissao =='acesso_total' || user.permissao =='relatorio'"
              @click="showModalNovoFechamentoGeral()"
                >{{$t("botoes.novo")}}</b-dropdown-item
              >
              <b-dropdown-item
              v-if="user.permissao =='acesso_total' || user.permissao =='relatorio'"
                @click="showModalFechamentoGeral()"
                :disabled="!fechamento"
                >{{$t("botoes.editar")}}</b-dropdown-item
              >
              <b-dropdown-item
              v-if="user.permissao =='acesso_total' || user.permissao =='relatorio'"
                @click="showModalConfigurarFechamento()"
                :disabled="!fechamento"
                >{{$t("botoes.configurarGrafico")}}</b-dropdown-item
              >
              <b-dropdown-item
                @click="gerarApresentacao()"
                :disabled="!fechamento"
                >{{$t("botoes.gerarApresentacao")}}</b-dropdown-item
              >
            </b-dropdown>
          </b-button-toolbar>
        </div>
      </b-row>
    </b-card>

    <div class="mt-3">
      <b-card no-body>
        <b-tabs card>
          <b-tab :title=" $t('tabs.componentes') " active>
            <Componente
              :componentes="familia.componentes"
              @editarComponente="showModalEditarComponente($event)"
            />
          </b-tab>
          <b-tab :title="'Cg '+ $t('tabs.geral') " v-if="resultado && fechamento_geral.imprimir.cg_geral">
            <div class="pagina-padrao">
              <Conformidade
                v-if="resultado"
                ref="fechamentoConformidade"
                calculo="CG"
                height="600"
                :semanas="resultado.geral.cg_semanas"
                :verde="resultado.geral.f_geral_cg_verde"
                :amarelo="resultado.geral.f_geral_cg_amarelo"
                :vermelho="resultado.geral.f_geral_cg_vermelho"
              />
              <b-alert v-else show variant="danger" class="mt-3">
                <span>
                  <b-icon
                    icon="exclamation-octagon"
                    aria-hidden="true"
                  ></b-icon>
                  {{ $t('alerta.selecione-o-fechamento-para-visualizar-o-grafico') }}
                </span>
              </b-alert>
            </div>
          </b-tab>
          <b-tab :title="'Cg '+ $t('tabs.porPeca') " v-if="resultado && fechamento_geral.imprimir.cg_pecas">
            <div class="pagina-padrao" ref="fechamentoConformidadePorPeca">
              <Conformidade
                v-if="resultado"                
                calculo="CG"
                height="470"
                :semanas="resultado.peca_reverse.f_geral_item_cg"
                :verde="resultado.peca_reverse.f_geral_cg_verde"
                :amarelo="resultado.peca_reverse.f_geral_cg_amarelo"
                :vermelho="resultado.peca_reverse.f_geral_cg_vermelho"
              />              
              <top-five
                :desenho="resultado.peca.f_geral_item_desenho_cg"
                :imagem="resultado.peca.f_geral_imagem_cg"
                :verde="resultado.peca.f_geral_cg_verde"
                :amarelo="resultado.peca.f_geral_cg_amarelo"
                :vermelho="resultado.peca.f_geral_cg_vermelho"              
              />           
            </div>
          </b-tab>
          <b-tab :title="indicadorCp + ' '+ $t('tabs.geral') " v-if="resultado && fechamento_geral.imprimir.cp_geral">
            <div class="pagina-padrao">
              <Conformidade
                v-if="resultado"
                ref="fechamentoCp"
                :semanas="resultado.geral.cp_semanas"
                :verde="resultado.geral.f_geral_cp_verde"
                :amarelo="resultado.geral.f_geral_cp_amarelo"
                :vermelho="resultado.geral.f_geral_cp_vermelho"
                :calculo="indicadorCp"
                height="600"
              />
              <b-alert v-else show variant="danger" class="mt-3">
                <span>
                  <b-icon
                    icon="exclamation-octagon"
                    aria-hidden="true"
                  ></b-icon>
                  {{ $t('alerta.selecione-o-fechamento-para-visualizar-o-grafico') }}
                </span>
              </b-alert>
            </div>
          </b-tab>
          <b-tab :title="indicadorCp + ' '+  $t('tabs.porPeca') " v-if="resultado && fechamento_geral.imprimir.cp_pecas">
            <div class="pagina-padrao" ref="fechamentoCpPorPeca">
              <Conformidade
                v-if="resultado"                
                :semanas="resultado.peca_reverse.f_geral_item_cp"
                :verde="resultado.peca_reverse.f_geral_cp_verde"
                :amarelo="resultado.peca_reverse.f_geral_cp_amarelo"
                :vermelho="resultado.peca_reverse.f_geral_cp_vermelho"
                :calculo="indicadorCp"
                height="470"
              />
              <top-five
                :desenho="resultado.peca.f_geral_item_desenho_cp"
                :imagem="resultado.peca.f_geral_imagem_cp"
                :verde="resultado.peca.f_geral_cp_verde"
                :amarelo="resultado.peca.f_geral_cp_amarelo"
                :vermelho="resultado.peca.f_geral_cp_vermelho"              
              />              
            </div>
          </b-tab>
          <b-tab :title="indicadorCp + 'K '+ $t('tabs.geral') " v-if="resultado && fechamento_geral.imprimir.cpk_geral">
            <div class="pagina-padrao">
              <Conformidade
                v-if="resultado"
                ref="fechamentoCpk"
                :semanas="resultado.geral.cpk_semanas"
                :verde="resultado.geral.f_geral_cpk_verde"
                :amarelo="resultado.geral.f_geral_cpk_amarelo"
                :vermelho="resultado.geral.f_geral_cpk_vermelho"
                :calculo="indicadorCp + 'K'"
                height="600"
              />
              <b-alert v-else show variant="danger" class="mt-3">
                <span>
                  <b-icon
                    icon="exclamation-octagon"
                    aria-hidden="true"
                  ></b-icon>
                  {{ $t('alerta.selecione-o-fechamento-para-visualizar-o-grafico') }}
                </span>
              </b-alert>
            </div>
          </b-tab>
          <b-tab :title="indicadorCp + 'K '+ $t('tabs.porPeca') " v-if="resultado && fechamento_geral.imprimir.cpk_pecas">
            <div class="pagina-padrao" ref="fechamentoCpkPorPeca">
              <Conformidade
                v-if="resultado"                
                :semanas="resultado.peca_reverse.f_geral_item_cpk"
                :verde="resultado.peca_reverse.f_geral_cpk_verde"
                :amarelo="resultado.peca_reverse.f_geral_cpk_amarelo"
                :vermelho="resultado.peca_reverse.f_geral_cpk_vermelho"
                :calculo="indicadorCp + 'K'"
                height="470"
              />
              <top-five
                :desenho="resultado.peca.f_geral_item_desenho_cpk"
                :imagem="resultado.peca.f_geral_imagem_cpk"
                :verde="resultado.peca.f_geral_cpk_verde"
                :amarelo="resultado.peca.f_geral_cpk_amarelo"
                :vermelho="resultado.peca.f_geral_cpk_vermelho"              
              />              
            </div>
          </b-tab>
          <b-tab :title="$t('tabs.painel-de-pecas')" v-if="fechamento_geral && fechamento_geral.relatorios.length > 0">
              <painel-pecas :itens="fechamento_geral.relatorios" v-if="fechamento_geral.relatorios"/>
          </b-tab>
          <b-tab v-if="carregandoFechamento">
            <template #title>
              <b-spinner type="border" small></b-spinner> {{ $t('modal.carregando') }}
            </template>
            <p class="p-3">{{ $t('carregando-informacoes') }}</p>
          </b-tab>
        </b-tabs>        
      </b-card>
    </div>
    <modal-familia :familia="familia" @listaMudou="carregarFamilia()" />
    <modal-componente
      :componente="componente"
      :familia="familia"
      @listaMudou="carregarFamilia()"
    />
    <modal-fechamento-geral
      :familia="familia"
      :fechamento_id="fechamento"
      @listaMudou="carregarFamilia()"
    >
    </modal-fechamento-geral>
    <modal-configurar-fechamento
      v-if="fechamento"
      :fechamento="fechamento"
      @configMudou="carregarFamilia()"
    />
    <modal-carregando-geral />
    <modal-carregando :count="count" :tabs="quantidade_paginas_imprimir" />
  </div>
</template>

<script>
import Componente from "@/components/Componentes.vue";
import Conformidade from "@/components/Conformidade.vue";
import ModalFamilia from "@/components/modal/Familia.vue";
import ModalComponente from "@/components/modal/Componente.vue";
import ModalFechamentoGeral from "@/components/modal/FechamentoGeral.vue";
import ModalConfigurarFechamento from "@/components/modal/ConfiguracaoFechamento.vue";
import ModalCarregandoGeral from "@/components/modal/CarregandoGeral.vue";
import ModalCarregando from "@/components/modal/Carregando.vue";
import TopFive from "@/components/TopFive.vue";
import PainelPecas from "@/views/relatorio/PainelPecas.vue";
import SelecaoPeriodo from "@/components/SelecaoPeriodo.vue";

import * as htmlToImage from "html-to-image";

export default {
  name: "Familia",
  components: {
    Componente,
    Conformidade,
    ModalFamilia,
    ModalComponente,
    ModalFechamentoGeral,
    ModalConfigurarFechamento,
    ModalCarregandoGeral,
    ModalCarregando,
    TopFive,
    PainelPecas,
    SelecaoPeriodo
  },
  computed: {
    filter(){
      return '?tipoPeriodo=' + this.$store.state.tipoPeriodo +
       '&ano=' + this.$store.state.ano + 
       '&mes=' + this.$store.state.mes + 
       '&semana=' + this.$store.state.semana + 
       '&data=' + this.$store.state.data + 
       '&turno=' + this.$store.state.turno;
    },
    user() {
            return this.$store.state.auth.user.user;
        },
    indicadorCp() {
      return this.indicador == "cp" ? "CP" : "PP";
    },
    turno: {
      get() {
        return this.$store.state.turno;
      },
      set(value) {
        this.$store.commit("setTurno", value);
      },
    },
    tipoPeriodo: {
      get() {
        return this.$store.state.tipoPeriodo;
      },
      set(value) {
        this.$store.commit("setTipoPeriodo", value);
      },
    },
    mes: {
      get() {
        return this.$store.state.mes;
      },
      set(value) {
        this.$store.commit("setMes", value);
      },
    },
    semana: {
      get() {
        return this.$store.state.semana;
      },
      set(value) {
        this.$store.commit("setSemana", value);
      },
    },
    visualizacao: {
      get() {
        return this.$store.state.visualizacao;
      },
      set(value) {
        this.$store.commit("setVisualizacao", value);
      },
    },
    data: {
      get() {
        return this.$store.state.data;
      },
      set(value) {
        this.$store.commit("setData", value);
      },
    },
    ano: {
      get() {
        return this.$store.state.ano;
      },
      set(value) {
        this.$store.commit("setAno", value);
      },
    }
  },
  data() {
    return {
      indicador: "cp",
      componente: {
        id: null,
        desenho: null,
        descricao: null,
        imagem: null,
        familia: {
          descricao: null,
        },
        relatorios: [],
      },
      url: null,
      familia: {
        id: null,
        descricao: null,
        imagem: null,
        componentes: [],
      },
      resultado: null,
      fechamento: null,
      fechamento_geral: null,
      optionsFechamentos: [],
      carregando: false,
      carregandoFechamento: false,
      tabs: [
        { tab: 1, ref: "fechamentoConformidade" },
        { tab: 2, ref: "fechamentoConformidadePorPeca" },
        { tab: 3, ref: "fechamentoCp" },
        { tab: 4, ref: "fechamentoCpPorPeca" },
        { tab: 5, ref: "fechamentoCpk" },
        { tab: 6, ref: "fechamentoCpkPorPeca" },
      ],
      screenshots: {
        fechamentoConformidade: [],
        fechamentoConformidadePorPeca: [],
        fechamentoCp: [],
        fechamentoCpPorPeca: [],
        fechamentoCpk: [],
        fechamentoCpkPorPeca: [],
      },
      count: 0,
      quantidade_paginas_imprimir: 0,
    };
  },
  methods: {    
    makeToast(variant = null) {
      this.$bvToast.toast(this.$t('toast.operacao-realizada-com-sucesso'), {
         title: this.$t('toast.sucesso'),
        variant: variant,
        solid: true,
      });
    },
    enviarDadosApresentacao() {
      let formData = new FormData();
      formData.append("fechamento_geral_id", this.fechamento);
      formData.append("semana", this.semana);
      formData.append("ano", this.ano);
      formData.append("tipo", "fechamento");

      if (this.screenshots.fechamentoConformidade) {
        this.screenshots.fechamentoConformidade.forEach((element) => {
          formData.append("fechamentoConformidade[]", element.src);
        });
      }
      if (this.screenshots.fechamentoConformidadePorPeca) {
        this.screenshots.fechamentoConformidadePorPeca.forEach((element) => {
          formData.append("fechamentoConformidadePorPeca[]", element.src);
        });
      }
      if (this.screenshots.fechamentoCp) {
        this.screenshots.fechamentoCp.forEach((element) => {
          formData.append("fechamentoCp[]", element.src);
        });
      }
      if (this.screenshots.fechamentoCpPorPeca) {
        this.screenshots.fechamentoCpPorPeca.forEach((element) => {
          formData.append("fechamentoCpPorPeca[]", element.src);
        });
      }
      if (this.screenshots.fechamentoCpk) {
        this.screenshots.fechamentoCpk.forEach((element) => {
          formData.append("fechamentoCpk[]", element.src);
        });
      }
      if (this.screenshots.fechamentoCpkPorPeca) {
        this.screenshots.fechamentoCpkPorPeca.forEach((element) => {
          formData.append("fechamentoCpkPorPeca[]", element.src);
        });
      }

      let config = {
        headers: {
          "Content-Type": "multipart/form-data",
          Accept: "application/json",
        },
        maxContentLength: 1000000000000,
        maxBodyLength: 10000000000000,
      };

      this.$http
        .post("api/apresentacao", formData, config)
        .then((response) => {
          var path =
            this.url + "/" + response.data.arquivo;
          var nome = response.data.arquivo;

          this.download(path, nome);

          setTimeout(() => {
            this.makeToast("success");
            this.capturing = false;
            this.count = 0;
            document.getElementById("app").style.backgroundColor = "";
            document.getElementById("app").style.opacity = "";

            var els = document.getElementsByClassName("tab-pane");

            Array.prototype.forEach.call(els, function (el, index) {
              if (index == 0) {
                el.style.display = "block";
              } else {
                el.style.display = "none";
              }
            });

            this.tabIndex = 0;

            this.$bvModal.hide("modal-carregando");
          }, 300);
        })
        .catch((errors) => {
          if (errors.response) {
            this.menssagem = errors.response.data;
          }

          this.carregando = false;
          this.capturing = false;
          this.count = 0;
          document.getElementById("app").style.backgroundColor = "";
          document.getElementById("app").style.opacity = "";

          var els = document.getElementsByClassName("tab-pane");

          Array.prototype.forEach.call(els, function (el, index) {
            if (index == 0) {
              el.style.display = "block";
            } else {
              el.style.display = "none";
            }
          });

          this.tabIndex = 0;
          this.$bvModal.hide("modal-carregando");
        });
    },
    download(file, nome) {
      window.location.href = file;
    },
    gerarApresentacao() {
      this.onCapture();
    },
    capiturando() {
      if (this.count == this.quantidade_paginas_imprimir) {
        setTimeout(() => {
          this.enviarDadosApresentacao();
        }, 300);
      }
    },
    onCapture() {
      this.capturing = true;

      document.getElementById("app").style.backgroundColor = "#e4e4e4";
      document.getElementById("app").style.opacity = 0;

      var els = document.getElementsByClassName("tab-pane");

      Array.prototype.forEach.call(els, function (el) {
        el.style.display = "block";
      });

      this.quantidade_paginas_imprimir = 0;
      this.count = 0;

      this.tabs.forEach((tab, i) => {
        if (tab.tab == 1) {
          if (this.fechamento_geral.imprimir.cg_geral == true) {
            this.quantidade_paginas_imprimir += 1;
          }
        }

        if (tab.tab == 2) {
          if (this.fechamento_geral.imprimir.cg_pecas == true) {
            this.quantidade_paginas_imprimir += 1;
          }
        }

        if (tab.tab == 3) {
          if (this.fechamento_geral.imprimir.cp_geral == true) {
            this.quantidade_paginas_imprimir += 1;
          }
        }

        if (tab.tab == 4) {
          if (this.fechamento_geral.imprimir.cp_pecas == true) {
            this.quantidade_paginas_imprimir += 1;
          }
        }

        if (tab.tab == 5) {
          if (this.fechamento_geral.imprimir.cpk_geral == true) {
            this.quantidade_paginas_imprimir += 1;
          }
        }

        if (tab.tab == 6) {
          if (this.fechamento_geral.imprimir.cpk_pecas == true) {
            this.quantidade_paginas_imprimir += 1;
          }
        }
      });

      this.showModalCarregando();

      this.screenshots = {
        fechamentoConformidade: [],
        fechamentoConformidadePorPeca: [],
        fechamentoCp: [],
        fechamentoCpPorPeca: [],
        fechamentoCpk: [],
        fechamentoCpkPorPeca: [],
      };

      setTimeout(() => {
        this.tabs.forEach((tab, i) => {
          if (tab.tab == 1) {
            if (this.fechamento_geral.imprimir.cg_geral == true) {
              setTimeout(() => {
                const capture = this.$refs[tab.ref].$el;
                htmlToImage
                  .toPng(capture)
                  .then((dataUrl) => {
                    var img = new Image();
                    img.src = dataUrl;
                    this.screenshots.fechamentoConformidade.push(img);
                    this.count++;
                    this.capiturando();
                  })
                  .catch((error) => {
                    console.error("oops, something went wrong!", error);
                  });
              }, 300);
            }
          }

          if (tab.tab == 2) {
            if (this.fechamento_geral.imprimir.cg_pecas == true) {
              setTimeout(() => {
                const capture = this.$refs[tab.ref];
                htmlToImage
                  .toPng(capture)
                  .then((dataUrl) => {
                    var img = new Image();
                    img.src = dataUrl;
                    this.screenshots.fechamentoConformidadePorPeca.push(img);
                    this.count++;
                    this.capiturando();
                  })
                  .catch((error) => {
                    console.error("oops, something went wrong!", error);
                  });
              }, 300);
            }
          }

          if (tab.tab == 3) {
            if (this.fechamento_geral.imprimir.cp_geral == true) {
              setTimeout(() => {
                const capture = this.$refs[tab.ref].$el;
                htmlToImage
                  .toPng(capture)
                  .then((dataUrl) => {
                    var img = new Image();
                    img.src = dataUrl;
                    this.screenshots.fechamentoCp.push(img);
                    this.count++;
                    this.capiturando();
                  })
                  .catch((error) => {
                    console.error("oops, something went wrong!", error);
                  });
              }, 300);
            }
          }

          if (tab.tab == 4) {
            if (this.fechamento_geral.imprimir.cp_pecas == true) {
              setTimeout(() => {
                const capture = this.$refs[tab.ref];
                htmlToImage
                  .toPng(capture)
                  .then((dataUrl) => {
                    var img = new Image();
                    img.src = dataUrl;
                    this.screenshots.fechamentoCpPorPeca.push(img);
                    this.count++;
                    this.capiturando();
                  })
                  .catch((error) => {
                    console.error("oops, something went wrong!", error);
                  });
              }, 300);
            }
          }

          if (tab.tab == 5) {
            if (this.fechamento_geral.imprimir.cpk_geral == true) {
              setTimeout(() => {
                const capture = this.$refs[tab.ref].$el;
                htmlToImage
                  .toPng(capture)
                  .then((dataUrl) => {
                    var img = new Image();
                    img.src = dataUrl;
                    this.screenshots.fechamentoCpk.push(img);
                    this.count++;
                    this.capiturando();
                  })
                  .catch((error) => {
                    console.error("oops, something went wrong!", error);
                  });
              }, 300);
            }
          }

          if (tab.tab == 6) {
            if (this.fechamento_geral.imprimir.cp_pecas == true) {
              setTimeout(() => {
                const capture = this.$refs[tab.ref];
                htmlToImage
                  .toPng(capture)
                  .then((dataUrl) => {
                    var img = new Image();
                    img.src = dataUrl;
                    this.screenshots.fechamentoCpkPorPeca.push(img);
                    this.count++;
                    this.capiturando();
                  })
                  .catch((error) => {
                    console.error("oops, something went wrong!", error);
                  });
              }, 300);
            }
          }
        });
      }, 600);
    },
    showModalCarregando() {
      this.$bvModal.show("modal-carregando");
    },
    showModalConfigurarFechamento() {
      this.$bvModal.show("modal-config-fechamento");
    },
    showModalNovoFechamentoGeral() {
      this.fechamento = null;
      this.$bvModal.show("modal-fechamento-geral");
    },
    showModalFechamentoGeral() {
      this.$bvModal.show("modal-fechamento-geral");
    },
    showModalFamilia() {
      this.$bvModal.show("modal-familia");
    },
    showModalComponente() {
      this.componente = {
        id: null,
        desenho: null,
        descricao: null,
        imagem: null,
        familia: {
          descricao: null,
        },
        relatorios: [],
      };
      this.$bvModal.show("modal-componente");
    },
    showModalEditarComponente(componente) {
      this.componente = componente;
      this.$bvModal.show("modal-componente");
    },
    carregarFamilia() {
      this.$bvModal.show("modal-carregando-geral");
      this.$http
        .get("api/familia/" + this.$route.params.id)
        .then((response) => {
          
          this.familia = response.data;
          this.carregarFechamentos();
          if (this.fechamento != null) {
            this.carregarFechamentoGeral();
          }else{
            this.$bvModal.hide("modal-carregando-geral");
          }
        })
        .catch((errors) => {
          console.log(errors);
        });
    },
    carregarFechamentos() {
      this.$http
        .get("api/fechamento-geral/familia/" + this.familia.id)
        .then((response) => {
          this.optionsFechamentos = [];
          response.data.forEach((element) => {
            this.optionsFechamentos.push({
              value: element.id,
              text: element.descricao,
            });
          });
        })
        .catch((errors) => {
          console.log(errors);
        });
    },
    carregarFechamentoGeral() {
      if (this.fechamento != null) {
        //this.$bvModal.show("modal-carregando-geral");
        //this.carregando = true;
        this.carregandoFechamento = true;
        this.menssagem = null;
        this.resultado = this.limpar;
        this.$http
          .get(
            "api/fechamento-geral/resultado/"+ this.fechamento + this.filter )
          .then((response) => {
            this.resultado = response.data;
            //this.carregando = false;           
            //this.$bvModal.hide("modal-carregando-geral");
            this.carregandoFechamento = false;
          })
          .catch((errors) => {
            this.menssagem = errors.response.data.erro;
            this.carregando = false;
            this.carregandoFechamento = false;
            this.$bvModal.hide("modal-carregando-geral");
          });
      }
      if (this.fechamento != null) {
        this.$bvModal.show("modal-carregando-geral");
        this.carregando = true;
        this.menssagem = null;
        this.fechamento_geral = this.limpar;
        this.$http
          .get("api/fechamento-geral/" + this.fechamento)
          .then((response) => {
            this.fechamento_geral = response.data;
            this.indicador = response.data.indicador;
            this.carregando = false;
            this.$bvModal.hide("modal-carregando-geral");
          })
          .catch((errors) => {
            this.fechamento_geral = errors.response.data.erro;
            this.carregando = false;
            //this.$bvModal.hide("modal-carregando-geral");
          });
      }
    },
  },
  mounted() {
    this.carregarFamilia();
    this.url = process.env.VUE_APP_ROOT_API;
  },
  watch: {
    fechamento: function () {
      this.carregarFechamentoGeral();
    },
    tipoPeriodo: function () {
      this.carregarFechamentoGeral();
    },
    turno: function () {
      this.carregarFechamentoGeral();
    },
    data: function () {
      this.carregarFechamentoGeral();
    },
    mes: function () {
      this.carregarFechamentoGeral();
    },
    semana: function () {
      this.carregarFechamentoGeral();
    },
    ano: function () {
      this.carregarFechamentoGeral();
    },
  },
};
</script>

<style scoped>
table.comBordaComplexa {
  text-align: center;
  font-weight: bold;
  border-collapse: collapse;
  /* CSS2 */
  background: #fffff0;
}

table.comBordaComplexa td {
  border: 2px solid white;
}

table.comBordaComplexa th {
  border: 1px solid blue;
  background: #f0fff0;
}

.pagina-padrao {
  width: 1035px;
  height: 652px;
  background-color: rgb(255, 255, 255);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.card-imagem {
  height: 150px;
}

.subtitle {
  font-weight: 500;
  color: gray;
}
</style>
