<template>
  <div>
    <div id="chart">
      <apexchart
        type="bar"
        :height="height"
        :options="chartOptions"
        :series="series"
      ></apexchart>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    semanas: [],
    verde: [],
    vermelho: [],
    amarelo: [],
    calculo: "",
    height: "",
    forecast: null,
  },
  data() {
    return {
      series: [
        {
          name: this.calculo + " ≤ 75%",
          data: [],
        },
        {
          name: "75% < " + this.calculo + " ≤ 100%",
          data: [],
        },
        {
          name: this.calculo + " > 100%",
          data: [],
        },
      ],
      chartOptions: {
        chart: {
          background: "#ffffff",
          height: 400,
          redrawOnParentResize: true,
          stacked: true,
          stackType: "100%",
          animations: {
            enabled: false,
          },
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              legend: {
                position: "bottom",
                offsetX: -10,
                offsetY: 0,
              },
            },
          },
        ],
        noData: {
          align: "center",
          text: "No data available at the moment",
          verticalAlign: "middle",
        },
        colors: ["#00FF05", "#FFFF00", "#FF0000"],
        dataLabels: {
          style: {
            fontSize: "14px",
            colors: ["#1C1C1C", "#1C1C1C", "#FFFFFF"],
          },
          formatter: function (value, { seriesIndex, dataPointIndex, w }) {  
            let valor = w.config.series[seriesIndex].data[dataPointIndex];
            if (valor) {
              valor = parseFloat(valor);
              return valor.toFixed(0);
            }        
            return w.config.series[seriesIndex].data[dataPointIndex];
          },
        },
        yaxis: {
          labels: {
            formatter: (value) => {
              return value + "%";
            },
          },
        },
        xaxis: {
          type: "category",
          categories: [],
        },
        fill: {
          opacity: 1,
        },
        legend: {
          position: "bottom",
          offsetX: 0,
          offsetY: 10,
        },
        annotations: {
          yaxis: [],
        },
      },
    };
  },
  mounted() {
    this.updateGrafico();
  },
  methods: {
    updateGrafico() {
      this.series[0].data = this.verde;
      this.series[1].data = this.amarelo;
      this.series[2].data = this.vermelho;
      this.chartOptions = {
        xaxis: {
          reversed: true,
          type: "category",
          categories: this.semanas,
        },
      };
      if(this.forecast){
        this.chartOptions.annotations = {        
          yaxis: [
            {
              y: this.forecast * 100,
              strokeDashArray: 10,
              borderColor: "#483D8B",
              label: {
                borderColor: "#483D8B",
                style: {
                  color: "#fff",
                  background: "#483D8B",
                },
                text: "ForeCast",
              },
            },
          ]        
      };

      }
    },
  },
  watch: {
    semanas: function () {
      this.updateGrafico();
    },
    verde: function () {
      this.updateGrafico();
    },
    amarelo: function () {
      this.updateGrafico();
    },
  },
};
</script>
