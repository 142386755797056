<template>
  <b-modal
    id="modal-fechamento-geral"
    size="xl"
    :title="$t('title.grafico-de-fechamento-geral')"
  >
    <template #modal-header>
      <!-- Emulate built in modal header close button action -->
      <h5>{{ $t("title.grafico-de-fechamento-geral") }}</h5>
      <p class="h4 btn">
        <b-icon icon="x" @click="close()"></b-icon>
      </p>
    </template>

    <b-form v-if="show">
      <b-row>
        <b-col>
          <b-form-group
            id="input-group-1"
            :label="$t('labe.nome')"
            :description="
              $t('descricao.digite-um-nome-para-o-novo-grafico-de-fechamento')
            "
          >
            <b-form-input
              v-model="form.descricao"
              type="text"
              :placeholder="$t('placeholder.entre-com-o-nome')"
              required
            ></b-form-input>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col sm="3">
          <b-card bg-variant="light" :header="$t('header.indicador')">
            <b-form-radio v-model="form.indicador" name="some-radios" value="cp"
              >Cp & Cpk</b-form-radio
            >
            <b-form-radio v-model="form.indicador" name="some-radios" value="pp"
              >Pp & Ppk</b-form-radio
            >
          </b-card>
        </b-col>
        <b-col sm="9">
          <b-card bg-variant="light" :header="$t('header.exibicao')">
            <b-row>
              <b-col sm="4">
                <b-form-checkbox-group
                  v-model="form.exibicao"
                  :options="optionsExibicaoConformidade"
                  stacked
                >
                </b-form-checkbox-group>
              </b-col>
              <b-col sm="4">
                <b-form-checkbox-group
                  v-model="form.exibicao"
                  :options="
                    form.indicador == 'cp'
                      ? optionsExibicaoCp
                      : optionsExibicaoPp
                  "
                  stacked
                ></b-form-checkbox-group>
              </b-col>
              <b-col sm="4">
                <b-form-checkbox-group
                  v-model="form.exibicao"
                  :options="
                    form.indicador == 'cp'
                      ? optionsExibicaoCpk
                      : optionsExibicaoPpk
                  "
                  stacked
                ></b-form-checkbox-group>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
      </b-row>
      <b-row class="mt-3">
        <b-col sm="6">
          <b-card
            bg-variant="light"
            :header="$t('header.produtos')"
            class="container-caracteristicas"
          >
            <b-form-group id="input-group-1">
              <b-form-select
                v-model="componente_selecionado"
                :options="componentes"
                :select-size="10"
                size="sm"
              >
              </b-form-select>
            </b-form-group>

            <b-row align-v="center">
              <b-col>
                <b-img
                  v-if="imagem"
                  center
                  height="115"
                  :src="imagem | urlImagem"
                />
                <img v-else src="../../assets/sem-foto.png" height="120" />
              </b-col>
              <b-col>
                <b-form-group id="input-group-2" :label="$t('label.relatorio')">
                  <b-form-select
                    v-model="relatorio_selecionado"
                    :options="relatorios"
                    :select-size="5"
                    size="sm"
                  >
                  </b-form-select>
                </b-form-group>
              </b-col>
            </b-row>

            <b-button size="sm" block v-on:click="addItem()">
              <b-icon icon="plus" aria-hidden="true"></b-icon
              >{{ $t("botoes.adicionar") }}
            </b-button>
          </b-card>
        </b-col>
        <b-col sm="6">
          <b-card
            bg-variant="light"
            :header="$t('header.itens-selecionados')"
            class="container-caracteristicas"
          >
            <b-form-group id="input-group-1">
              <b-form-select
                v-model="item_selecionado"
                :options="form.itens"
                :select-size="10"
                size="sm"
              >
              </b-form-select>
            </b-form-group>

            <b-row align-v="center">
              <b-col>
                <b-img
                  v-if="imagem_relatorio"
                  center
                  height="115"
                  :src="imagem_relatorio | urlImagem"
                />
                <img v-else src="../../assets/sem-foto.png" height="120" />
              </b-col>
              <b-col>
                <b-button size="sm" block v-on:click="removeItem()">
                  <b-icon icon="trash" aria-hidden="true"></b-icon>
                  {{ $t("botoes.remover-item-selecionado") }}
                </b-button>
                <b-button size="sm" block v-on:click="removeItens()">
                  <b-icon icon="trash" aria-hidden="true"></b-icon>
                  {{ $t("botoes.remover-todos-os-itens") }}
                </b-button>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
      </b-row>
    </b-form>

    <b-alert show variant="danger" v-if="menssagem" class="mt-3">
      <ul v-if="menssagem">
        <li v-for="(e, key) in menssagem" :key="key">{{ e[0] }}</li>
      </ul>
    </b-alert>

    <template #modal-footer>
      <div class="w-100">
        <b-row align-v="center">
          <b-col cols="12" md="8">
            <b-row align-v="center" align-h="center">
              <b-col>
                <p class="h1 ml-3">
                  <b-icon icon="exclamation-octagon" variant="primary"></b-icon>
                </p>
              </b-col>
              <b-col cols="12" md="10">
                <p class="pt-2">
                  <span class="text-primary"> {{ $t("modal.importante") }}</span
                  ><br />
                  {{ $t("modal.preenchaTodosOsDadosCorretamente") }}
                </p>
              </b-col>
            </b-row>
          </b-col>
          <b-col cols="6" md="4">
            <b-button
              variant="primary"
              class="float-right"
              v-if="carregando"
              disabled
            >
              <b-icon icon="arrow-clockwise" animation="spin-pulse"></b-icon>
              {{ $t("botoes.carregando") }}
            </b-button>

            <b-button
              v-else
              type="submit"
              variant="primary"
              class="float-right"
              @click="salvarFechamentoGeral()"
            >
              {{ $t("botoes.salvar") }}
            </b-button>
            <b-button
              v-if="fechamento_id"
              type="submit"
              variant="danger"
              class="float-right mr-2"
              @click="showMsgConfirmation()"
            >
              {{ $t("botoes.deletar") }}
            </b-button>
          </b-col>
        </b-row>
      </div>
    </template>
  </b-modal>
</template>

<script>
export default {
  props: {
    fechamento_id: null,
    familia: null,
  },
  data() {
    return {
      form: {
        indicador: "cp",
        exibicao: [],
        descricao: null,
        itens: [],
        familia_id: null,
      },
      optionsExibicaoConformidade: [
        {
          text: this.$t("opcoes.conformidade.conformidade-geral"),
          value: "cg-geral",
        },
        {
          text: this.$t("opcoes.conformidade.conformidade-por-pecas"),
          value: "cg-pecas",
        },
      ],
      optionsExibicaoCp: [
        { text: this.$t("opcoes.cp.cp-geral"), value: "cp-geral" },
        { text: this.$t("opcoes.cp.cp-por-pecas"), value: "cp-pecas" },
      ],
      optionsExibicaoCpk: [
        { text: this.$t("opcoes.cpk.cpk-geral"), value: "cpk-geral" },
        { text: this.$t("opcoes.cpk.cpk-por-pecas"), value: "cpk-pecas" },
      ],
      optionsExibicaoPp: [
        { text: this.$t("opcoes.pp.pp-geral"), value: "cp-geral" },
        { text: this.$t("opcoes.pp.pp-por-pecas"), value: "cp-pecas" },
      ],
      optionsExibicaoPpk: [
        { text: this.$t("opcoes.ppk.ppk-geral"), value: "cpk-geral" },
        { text: this.$t("opcoes.ppk.ppk-por-pecas"), value: "cpk-pecas" },
      ],
      show: true,
      menssagem: null,
      carregando: null,
      componentes: [],
      componente_selecionado: null,
      relatorios: [],
      relatorio_selecionado: null,
      imagem: null,
      item_selecionado: null,
      imagem_relatorio: null,
    };
  },
  methods: {
    salvarFechamentoGeral() {
      this.menssagem = null;
      this.carregando = true;

      let config = {
        headers: {
          Accept: "application/json",
        },
      };

      if (this.fechamento_id != null) {
        this.form._method = "patch";
        this.$http
          .post(
            "api/fechamento-geral/" + this.fechamento_id,
            this.form,
            config
          )
          .then((response) => {
            this.makeToast("success");
            this.$bvModal.hide("modal-fechamento-geral");
            this.carregando = false;
            this.$emit("listaMudou");
          })
          .catch((errors) => {
            this.menssagem = errors.response.data.errors;
            this.carregando = false;
          });
      } else {
        this.$http
          .post("api/fechamento-geral", this.form, config)
          .then((response) => {
            this.makeToast("success");
            this.$bvModal.hide("modal-fechamento-geral");
            this.carregando = false;
            this.$emit("listaMudou");
          })
          .catch((errors) => {
            this.menssagem = errors.response.data.errors;
            this.carregando = false;
          });
      }
    },
    makeToast(variant = null) {
      this.$bvToast.toast(this.$t("toast.operacao-realizada-com-sucesso"), {
        title: this.$t("toast.sucesso"),
        variant: variant,
        solid: true,
      });
    },
    close() {
      this.$bvModal.hide("modal-fechamento-geral");
    },
    addItem() {
      if (this.componente_selecionado != null) {
        this.carregarRelatorio();
      }
    },
    removeItem() {
      if (this.item_selecionado != null) {
        var index = this.form.itens
          .map(function (item) {
            return item.value;
          })
          .indexOf(this.item_selecionado);
        if (index > -1) {
          this.form.itens.splice(index, 1);
        }
      }
      this.item_selecionado = null;
      this.imagem_relatorio = null;
    },
    removeItens() {
      this.form.itens = [];
      this.item_selecionado = null;
      this.imagem_relatorio = null;
    },
    carregarComponentes() {
      this.carregando = true;
      this.menssagem = null;

      this.$http
        .get("api/componente")
        .then((response) => {
          response.data.forEach((item) => {
            this.componentes.push({
              value: item.id,
              text:
                item.familia.descricao +
                " - " +
                item.descricao +
                " - " +
                item.desenho,
            });
          });

          this.carregando = false;
        })
        .catch((errors) => {
          this.menssagem = errors.response.data.erro;
          this.carregando = false;
        });
    },

    carregarComponente() {
      this.carregando = true;
      this.menssagem = null;
      this.relatorios = [];

      this.$http
        .get("api/componente/" + this.componente_selecionado)
        .then((response) => {
          response.data.relatorios.forEach((item) => {
            this.relatorios.push({
              value: item.id,
              text: item.descricao,
            });
          });

          this.imagem = response.data.imagem;

          this.carregando = false;
        })
        .catch((errors) => {
          this.menssagem = errors.response.data.erro;
          this.carregando = false;
        });
    },

    carregarRelatorio() {
      this.carregando = true;
      this.menssagem = null;

      this.$http
        .get("api/relatorio/simples/" + this.relatorio_selecionado)
        .then((response) => {
          var c = {
            value: this.relatorio_selecionado,
            text:
              response.data.componente.familia.descricao +
              " - " +
              response.data.componente.descricao +
              " - " +
              response.data.componente.desenho +
              " - " +
              response.data.descricao,
          };
          this.form.itens.push(c);

          this.carregando = false;
        })
        .catch((errors) => {
          this.menssagem = errors.response.data.erro;
          this.carregando = false;
        });
    },

    carregarDadosRelatorio() {
      this.carregando = true;
      this.menssagem = null;

      this.$http
        .get("api/relatorio/simples/" + this.item_selecionado)
        .then((response) => {
          this.imagem_relatorio = response.data.componente.imagem;

          this.carregando = false;
        })
        .catch((errors) => {
          this.menssagem = errors.response.data.erro;
          this.carregando = false;
        });
    },

    carregarFechamentoGeral() {
      this.$http
        .get("api/fechamento-geral/" + this.fechamento_id)
        .then((response) => {
          this.form.indicador = response.data.indicador;
          this.form.descricao = response.data.descricao;
          this.form.itens = response.data.itens;
          this.form.familia_id = response.data.familia_id;
          this.form.exibicao = response.data.exibicao;
          this.carregando = false;
        })
        .catch((errors) => {
          this.menssagem = errors.response.data.erro;
          this.carregando = false;
        });
    },
    showMsgConfirmation() {
      this.$bvModal
        .msgBoxConfirm(
          this.$t("confirmacao.tem-certeza-que-deseja-remover-esse-fechamento"),
          {
            title: this.$t("title.confirme-por-favor"),
            size: "sm",
            buttonSize: "sm",
            okVariant: "danger",
            okTitle: this.$t("confirmacao.sim"),
            cancelTitle: this.$t("confirmacao.nao"),
            footerClass: "p-2",
            hideHeaderClose: false,
            centered: true,
          }
        )
        .then((value) => {
          if (value == true) {
            this.deletar();
          } else {
            return false;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    deletar() {
      let formData = new FormData();
      formData.append("_method", "delete");

      let config = {
        headers: {
          "Content-Type": "multipart/form-data",
          Accept: "application/json",
        },
      };

      this.$http
        .post("api/fechamento-geral/" + this.fechamento_id, formData, config)
        .then((response) => {
          this.makeToast("success");
          this.$bvModal.hide("modal-fechamento-geral");
          this.carregando = false;
          this.$emit("listaMudou");
        })
        .catch((errors) => {
          this.menssagem = errors.response.data.errors;
          this.carregando = false;
        });
    },
  },
  computed: {},
  mounted() {
    this.carregarComponentes();
    if (this.fechamento_id != null) {
      this.carregarFechamentoGeral();
    }
  },
  watch: {
    componente_selecionado: function () {
      this.carregarComponente();
    },
    item_selecionado: function (item) {
      if (item) this.carregarDadosRelatorio();
    },
    fechamento_id: function () {
      if (this.fechamento_id != null) {
        this.carregarFechamentoGeral();
      } else {
        this.form = {
          indicador: "cp",
          exibicao: [],
          descricao: null,
          itens: [],
          familia_id: null,
        };
      }
    },
    familia: function () {
      this.form.familia_id = this.familia.id;
      if (!this.fechamento_id) {
        this.form.descricao = this.familia.descricao;
      }
    },
  },
};
</script>

<style scoped>
.table-wrapper {
  max-height: 200px !important;
  overflow: auto;
}

.container-caracteristicas {
  height: 100%;
}

.text-radio {
  font-size: 14px;
}
</style>
