<template>
  <b-modal id="modal-relatorio" size="lg" :title="$t('title.automarb-relatorio')">
    <template #modal-header>
      <!-- Emulate built in modal header close button action -->
      <h5>{{ $t('title.automarb-relatorio') }}</h5>
      <p class="h4 btn"><b-icon icon="x" @click="close()"></b-icon></p>
    </template>
    <b-form v-if="show">
      <b-form-group id="input-group-1" :label="$t('label.familia')" label-for="input-1">
        <b-form-input id="input-1" v-model="form.componente.descricao" type="text"
          :placeholder="$t('placeholder.entre-com-o-nome')" required disabled></b-form-input>
      </b-form-group>

      <b-form-group id="input-group-1" :label="$t('label.descricao')" label-for="input-1"
        :description="$t('descricao.digite-uma-descricao-para-criar-e-nomear-um-relatorio-de-pecas-conjuntos-relatorios-ect')">
        <b-form-input id="input-1" v-model="form.nome" type="text"
          :placeholder="$t('placeholder.entre-com-a-descricao')" required></b-form-input>
      </b-form-group>

      <b-form-group :label="$t('label.linha')">
        <b-form-select :options="linhas" v-model="form.linha"></b-form-select>
      </b-form-group>

      <b-form-checkbox v-model="simetria" class="mt-2 mb-3" v-if="!importar">
        Fazer simetria de um relatório
      </b-form-checkbox>

      <b-form-checkbox v-model="importar" class="mt-2 mb-3" v-if="!simetria">
        importar de um arquivo
      </b-form-checkbox>

      <template v-if="!simetria && !importar">

        <b-form-group id="input-group-2" :label="$t('label.controle-dimensional')" label-for="input-2"
          v-if="form.conversor != 6 && form.conversor != 8">
          <b-form-file v-model="form.controle" multiple
            :placeholder="$t('placehoader.escolha-um-controle-ou-solte-o-aqui')"
            :drop-placeholder="$t('drop.placehoader.solte-o-controle-aqui')"></b-form-file>
        </b-form-group>

        <template v-if="form.conversor == 6 || form.conversor == 8">

          <!-- <b-form-group :label="$t('label.caminho-programa-winfas')">
            <b-form-input v-model="form.diretorio_programa_winfas" type="text"
              :placeholder="$t('placeholder.entre-com-o-diretorio')" required></b-form-input>
          </b-form-group> -->

          <b-form-group :label="$t('label.codigo-programa-winfas')">
            <b-form-input v-model="form.codigo_programa_winfas" type="number"
              :placeholder="$t('placeholder.entre-com-o-codigo')" required></b-form-input>
          </b-form-group>

        </template>

        <b-form-group :label="$t('label.tipo-de-arquivo')">
          <b-form-radio-group size="sm" v-model="form.conversor" :options="optionsConversores"
            name="radio-options-conversor"></b-form-radio-group>
        </b-form-group>

        <b-form-group :label="$t('label.tipo-de-importacao')" v-if="form.conversor == 5">
          <b-form-radio-group size="sm" v-model="form.importacao" :options="optionsImportacao"
            name="radio-options-importacao"></b-form-radio-group>
        </b-form-group>

      </template>

      <template v-if="simetria && !importar">

        <b-form-group :label="$t('label.origem')">
          <b-form-select :options="componentes" v-model="componente_selecionado"></b-form-select>
        </b-form-group>

        <b-form-group :label="$t('label.relatorio')">
          <b-form-select :options="relatorios" v-model="form.relatorio_id_origem"></b-form-select>
        </b-form-group>

      </template>

      <template v-if="importar && !simetria">

        <b-form-group id="input-group-2" :label="$t('label.arquivo-compactado')" label-for="input-2">
          <b-form-file v-model="form.arquivo" :placeholder="$t('placehoader.escolha-o-arquivo-ou-solte-o-aqui')"
            :drop-placeholder="$t('drop.placehoader.solte-o-arquivo-aqui')"></b-form-file>
        </b-form-group>

      </template>



    </b-form>

    <b-alert show variant="danger" v-if="menssagemUnica">
      {{ menssagemUnica }}
    </b-alert>

    <b-alert show variant="danger" v-if="menssagem">
      <ul v-if="menssagem">
        <li v-for="(e, key) in menssagem" :key="key">{{ e[0] }}</li>
      </ul>
    </b-alert>

    <template #modal-footer>
      <div class="w-100">
        <b-row align-v="center">
          <b-col cols="12" md="8">
            <b-row align-v="center" align-h="center">
              <b-col>
                <p class="h1 ml-3">
                  <b-icon icon="exclamation-octagon" variant="primary"></b-icon>
                </p>
              </b-col>
              <b-col cols="12" md="10">
                <p class="pt-2">
                  <span class="text-primary"> {{ $t('modal.importante') }}</span><br />
                  {{ $t('modal.preenchaTodosOsDadosCorretamente') }}
                </p>
              </b-col>
            </b-row>
          </b-col>
          <b-col cols="6" md="4">
            <b-button variant="primary" class="float-right" v-if="carregando" disabled>
              <b-icon icon="arrow-clockwise" animation="spin-pulse"></b-icon>
              {{ $t('botoes.carregando') }} </b-button>

            <b-button v-else type="submit" variant="primary" class="float-right" @click="salvar()">
              {{ $t('botoes.salvar') }} </b-button>

            <b-button v-if="relatorio.id && user.permissao == 'acesso_total'" type="submit" variant="danger"
              class="float-right mr-2" @click="deletar()">
              {{ $t('botoes.deletar') }} </b-button>
          </b-col>
        </b-row>
      </div>
    </template>
  </b-modal>
</template>

<script>
export default {
  props: {
    componente: {
      type: Object,
      default: function () {
        return {
          id: null,
          desenho: '',
          descricao: '',
        };
      },
    },
    relatorio: {
      type: Object,
      default: function () {
        return {
          id: null,
          descricao: '',
          linha: null,
          componente: {
            id: null,
            descricao: '',
            desenho: '',
          },
        };
      },
    },
  },
  data() {
    return {
      form: {
        linha: null,
        diretorio_programa_winfas: "",
        codigo_programa_winfas: 100,
        nome: "",
        controle: null,
        conversor: 6,
        importacao: 1,
        componente: {
          id: null,
          descricao: '',
          desenho: '',
        },
        relatorio_id_origem: null,
        arquivo: null
      },
      componentes: [],
      componente_selecionado: null,
      relatorios: [],
      simetria: false,
      importar: false,
      show: true,
      menssagem: null,
      menssagemUnica: null,
      carregando: null,
      url: "api/relatorio",
      optionsConversores: [
        { text: "Winfas (Access)", value: 6 },
        { text: "Winfas (SQL Server)", value: 8 },
        { text: "Marposs", value: 7 },
        { text: "PC-Dmis", value: 1 },
        { text: "PolyWorks", value: 5 },
        { text: "Metrolog", value: 2 },
      ],
      optionsImportacao: [
        { text: this.$t('opcoes.importacao-unico'), value: 1 },
        { text: this.$t('opcoes.impotacao-mesclar'), value: 2 }
      ],
      linhas: [],
    };
  },
  computed: {
    user() {
      return this.$store.state.auth.user.user;
    },
  },
  methods: {
    makeToast(variant = null) {
      this.$bvToast.toast(this.$t('toast.operacao-realizada-com-sucesso'), {
        title: this.$t('toast.sucesso'),
        variant: variant,
        solid: true,
      });
    },
    close() {
      //this.limpar();
      this.$bvModal.hide("modal-relatorio");
    },
    limpar() {
      this.form.nome = "";
      this.form.controle = "";
      this.form.componente = "";
      this.form.conversor = 6;
      this.menssagem = null;
      this.menssagemUnica = null;
    },
    salvar() {
      this.menssagem = null;
      this.menssagemUnica = null;
      this.carregando = true;

      let formData = new FormData();
      formData.append("descricao", this.form.nome);
      formData.append("componente_id", this.form.componente.id);
      formData.append("conversor", this.form.conversor);
      formData.append("linha_id", this.form.linha);

      if (this.simetria) {
        formData.append("relatorio_id_origem", this.form.relatorio_id_origem);
      } else if (this.importar) {
        formData.append("arquivo", this.form.arquivo);
      } else {

        if (this.form.controle) {
          this.form.controle.forEach((element) => {
            formData.append("controle[]", element);
          });
          formData.append("conversor", this.form.conversor);
          formData.append("importacao", this.form.importacao);
        }
      }

      if (this.form.conversor == 6 || this.form.conversor == 8) {
        if(this.form.diretorio_programa_winfas){
          formData.append("diretorio_programa_winfas", this.form.diretorio_programa_winfas);
        }
        if(this.form.codigo_programa_winfas){
          formData.append("codigo_programa_winfas", this.form.codigo_programa_winfas);
        }        
      }

      if (this.relatorio.id) {
        formData.append("_method", "patch");
      }

      let config = {
        headers: {
          "Content-Type": "multipart/form-data",
          Accept: "application/json",
        },
      };

      this.$http
        .post(this.url, formData, config)
        .then((response) => {
          this.makeToast("success");
          this.$bvModal.hide("modal-relatorio");
          this.carregando = false;
          this.$router.push("/relatorio/" + response.data.id);
          this.$emit("listaMudou");
          //limpar()
        })
        .catch((errors) => {
          console.log(errors.response.data.erro)
          if (errors.response.data.erro) {
            this.menssagemUnica = errors.response.data.erro;
          } else {
            this.menssagem = errors.response.data.errors;
          }
          this.carregando = false;
        });
    },
    deletar() {
      let confirmacao = confirm(
        this.$t('confirmacao.tem-certeza-que-deseja-remover-esse-relatorio-com-todos-seus-relatorios')
      );

      if (!confirmacao) {
        return false;
      }

      let formData = new FormData();
      formData.append("_method", "delete");
      this.url = "api/relatorio/" + this.relatorio.id;

      let config = {
        headers: {
          "Content-Type": "multipart/form-data",
          Accept: "application/json",
        },
      };

      this.$http
        .post(this.url, formData, config)
        .then((response) => {
          this.makeToast("success");
          this.$bvModal.hide("modal-relatorio");
          this.carregando = false;
          this.$router.push("../componente/" + this.relatorio.componente.id);
        })
        .catch((errors) => {
          this.menssagem = errors.response.data.errors;
          this.carregando = false;
        });
    },

    carregarComponentes() {
      this.carregando = true;
      this.menssagem = null;

      this.$http
        .get("api/componente")
        .then((response) => {
          response.data.forEach((item) => {
            this.componentes.push({
              value: item.id,
              text:
                item.familia.descricao +
                " - " +
                item.descricao +
                " - " +
                item.desenho,
            });
          });

          this.carregando = false;
        })
        .catch((errors) => {
          this.menssagem = errors.response.data.erro;
          this.carregando = false;
        });
    },

    carregarLinhas() {
      this.carregando = true;
      this.menssagem = null;

      this.$http
        .get("api/linha")
        .then((response) => {
          response.data.forEach((item) => {
            this.linhas.push({
              value: item.id,
              text: item.descricao
            });
          });

          this.carregando = false;
        })
        .catch((errors) => {
          this.menssagem = errors.response.data.erro;
          this.carregando = false;
        });
    },

    carregarComponente() {
      this.carregando = true;
      this.menssagem = null;
      this.relatorios = [];

      this.$http
        .get("api/componente/" + this.componente_selecionado)
        .then((response) => {
          response.data.relatorios.forEach((item) => {
            this.relatorios.push({
              value: item.id,
              text: item.descricao,
            });
          });

          this.imagem = response.data.imagem;

          this.carregando = false;
        })
        .catch((errors) => {
          this.menssagem = errors.response.data.erro;
          this.carregando = false;
        });
    },
  },
  mounted() {
    this.carregarComponentes();
    this.carregarLinhas();
    if (this.relatorio.id) {
      this.form.diretorio_programa_winfas = this.relatorio.diretorio_programa_winfas;
      this.form.codigo_programa_winfas = this.relatorio.codigo_programa_winfas;
      this.form.nome = this.relatorio.descricao;
      this.form.linha = this.relatorio.linha_id;
      this.form.componente.id = this.relatorio.componente.id;
      this.form.componente.descricao = this.relatorio.componente.descricao;
      this.url = "api/relatorio/" + this.relatorio.id;
    }
  },
  watch: {
    componente_selecionado: function () {
      if (this.componente_selecionado)
        this.carregarComponente();
    },
    relatorio: function (relatorio) {
      this.form.diretorio_programa_winfas = this.relatorio.diretorio_programa_winfas;
      this.form.codigo_programa_winfas = this.relatorio.codigo_programa_winfas;
      this.form.nome = relatorio.descricao;
      this.form.linha = relatorio.linha_id;
      this.form.componente.id = relatorio.componente.id;
      this.form.componente.descricao = relatorio.componente.descricao;
      this.url = "api/relatorio/" + this.relatorio.id;
    },
    componente: function (componente) {
      this.form.componente = componente;
    },
  },
};
</script>

<style></style>